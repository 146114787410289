<template>
  <div class="main-container">
    <div class="main-heading">
      <h1>{{$t('PROMOTIONS')}}</h1>
      <button @click="localizationCSV_Export" class="success">{{ $t('EXPORT CSV') }}</button>
    </div>
    <div class="content-container">
      <div class="table">
        <div class="table-row header-row alternate">
          <div class="cell">{{ $t('ID') }}</div>
          <div class="cell">{{ $t('TITLE') }}</div>
          <div class="cell">{{ "Activation Time" }}</div>
          <div class="cell">{{ $t('PROMOTION_VALUES.ACTIVE_UNTIL') }}</div>
          <div class="cell">{{ $t('PROMOTION_VALUES.EXPIRES_IN') }}</div>
          <div class="cell">{{ $t('EVENT_VALUES.STATE') }}</div>
          <div class="cell">{{ $t('PROMOTION_VALUES.PRICE') }}</div>
          <div class="cell action-cell"><button @click="$router.push({ name: 'PromotionAdd' })" class="success">{{ $t('ADD') }}</button></div>
        </div>
        <div class="table-row" v-for="(promotion, index) in promotionsArr.filter(x => x.type === 0)" v-bind:key="promotion.id" :class="index % 2 === 0 ? '' : 'alternate'">
          <div class="cell">{{ promotion.id }}</div>
          <div class="cell">{{ promotion.title }}</div>
          <div class="cell">{{ moment(promotion.activation_time).format('DD/MM/YYYY HH:mm') }}</div>
          <div class="cell">{{ moment(promotion.active_until).format('DD/MM/YYYY HH:mm') }}</div>
          <div class="cell">{{ promotion.expires_in / (60*60*1000) }}</div>
           <div class="cell">
            <label :class="getPromoState(promotion).class">
              {{ getPromoState(promotion).text }}
            </label>
          </div>
          <div class="cell">{{ promotion.price }}</div>
          <div class="cell action-cell">
            <button @click="$router.push({ name: 'PromotionAdd', query: { id: promotion.id } })" class="info">{{ $t('EDIT') }}</button>
            <button @click="() => deletePromotion(promotion.id)" class="error">{{ $t('REMOVE') }}</button>
          </div>
        </div>
        <hr />
        <div class="table-row" v-for="(promotion, index) in promotionsArr.filter(x => x.type === 2)" v-bind:key="promotion.id" :class="index % 2 === 0 ? '' : 'alternate'">
          <div class="cell">{{ promotion.id }}</div>
          <div class="cell">{{ promotion.title }}</div>
          <div class="cell">{{ moment(promotion.activation_time).format('DD/MM/YYYY HH:mm') }}</div>
          <div class="cell">{{ moment(promotion.active_until).format('DD/MM/YYYY HH:mm') }}</div>
          <div class="cell">{{ promotion.expires_in / (60*60*1000)}}</div>
          <div class="cell">
            <label :class="getPromoState(promotion).class">
              {{ getPromoState(promotion).text }}
            </label>
          </div>
          <div class="cell">{{ promotion.price }}</div>
          <div class="cell action-cell">
            <a :href="'/promotions/add?id=' + promotion.id" class="info">{{ $t('EDIT') }}</a>
            <button @click="() => deletePromotion(promotion.id)" class="error">{{ $t('REMOVE') }}</button>
          </div>
        </div>
      </div>
    </div>
        <ConfirmPopup :popup-open="popupOpen" :text="popupText" @popup-close="popupOpen=false" :type="popupType"/>

  </div>
</template>

<script>
import { watch, ref, onBeforeMount, onUpdated, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import promotionSchema from "@/schemas/promotion";
import csvUtil from "@/util/csvBuilder"
import promotionConstants from '../../constants/promotionConstants'
import dispatchMap from "@/constants/dispatchMap";
import { io } from "socket.io-client";
import ConfirmPopup from '../../components/common/ConfirmPopup.vue'
export default {
  name: "Promotions",
  components: {
    ConfirmPopup
  },
  setup() {
    const store = useStore();
    const usedPages = ["promotionsIndex"];
    const socket = io(process.env.VUE_APP_BACKEND_URL);

    onBeforeMount(() => {
      usedPages.forEach(page => {
          dispatchMap[page].forEach((dispatchStr) => {
            store.dispatch(dispatchStr);
          })
      });
      }
    );
    const promotionsArr = ref([])
    const popupOpen = ref(false);
    const popupText = ref("This page is edited by another user. Please refresh before making changes.")
    const popupType = ref("refresh")
    const promotionTypes = promotionConstants.PROMOTION_TYPES

    onUpdated(() => store.dispatch("loader/loadingStatus", false));
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));
    
    watch(() => store.getters['promotions/getPromotions'], value => {
      promotionsArr.value = [ ...value ].filter(x => x.type === promotionTypes.DEFAULT || x.type === promotionTypes.IN_GAME_CURRENCY_PROMO).sort((a, b) => (a.activation_time||0) - (b.activation_time||0)) 
    }, { immediate: true })

    const localizationCSV_Export = () => {
      csvUtil.buildExportCsv("promotions", promotionSchema().localizables, promotionsArr.value)
    }

    const getPromoState = (promotion) => {
      const promoExpire = promotion.active_until
      const promoActivateDate = promotion.activation_time
      const promoRealExpire = promotion.active_until + promotion.expires_in
      const now = Date.now()

      if (now < promoActivateDate) {
        return { class: 'scheduled', text: 'SCHEDULED' }
      } else if (now < promoExpire) {
        //Active I
        return { class: 'active', text: 'ACTIVE' }
      } else if (now < promoRealExpire) {
        //Active II
        return { class: 'active2', text: 'BUYABLE' }
      } else {
        //EXPIRED
        return { class: 'ended', text: 'EXPIRED' }
      }
    }

    const deletePromotion = id => {
      store.dispatch('promotions/deletePromotion', { id })
    }

    socket.on('added', (data) => {
      if (data.page === "Promotion"){
        popupOpen.value = true;
      }
    })
    return {
      promotionsArr,
      moment,
      deletePromotion,
      localizationCSV_Export,
      getPromoState,
      popupOpen,
      popupText,
      popupType
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.content-container {
  width: 100%;
}
.table {

}
.table-row {
  padding: 10px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 3fr 2fr 2fr 2fr 2fr 2fr 2fr;
  text-align: start;
}

.table-row.alternate {
  background-color: lightgray;
}
.table-row.header-row {
  font-weight: 600;
  font-size: 1.125rem;
}
.cell {
  padding: 0 10px;
}

.main-heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

button, a {
  font-size: 0.9rem;
  font-weight: 400;
  display: block;
  text-decoration: none;
  color: black;
  flex-grow: 1;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.action-cell {
  display: flex;
}

button.error, a.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success, a.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info, a.info {
  background-color: rgba(0, 0, 255, 0.15);
}

label {
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
}

label.ended {
  background-color: rgba(255, 0, 0, 0.15);
}

label.active {
  background-color: rgba(0, 255, 0, 0.15);
}

label.scheduled {
  background-color: rgba(0, 0, 255, 0.15);
}

label.active2 {
  background-color: rgba(88, 255, 152, 0.15);
}
</style>